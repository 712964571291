import React, { useContext } from 'react';
import { BsArrowReturnRight } from 'react-icons/bs';
import { GlobalDataContext } from '../../context/context';
import { ButtonContent } from '../global/boton/ButtonContent';


import imgDefault from '../../assets/image/placeholder.png'


const BlockSix = ({ title, text, listsAbout, listsServices, image1,image2,image3,image4, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='bg-[#efefef] py-[150px]'>
            <div className='w-[95%] md:w-[90%] mx-auto'>
                <div className=' bgBloque flex flex-col-reverse md:flex-row px-5 md:px-10'>
                    <div className='w-full md:w-1/2 bg-white mr-0 -mb-20 mt-5 md:mr-2 md:-my-20 px-10 py-12 text-center md:text-start'>
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed.length > 1 ?
                                    <h2 className='pb-3 capitalize'>
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h2>
                                    : <h2 className='pb-3 capitalize'>we have {rpdata?.dbPrincipal?.exprYears} years of experience </h2>
                                :
                                <h2 className='pb-3 capitalize'>
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h2>
                        }
                        <p className='pb-3'>
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        {
                            listsAbout ?
                                <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                    {
                                        rpdata?.dbAbout?.[0].list.length > 1 ?
                                            rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                return (

                                                    <li key={index} className="py-2 flex items-center">
                                                        <BsArrowReturnRight />
                                                        <span className="pl-2">{item}</span>
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                                : null
                        }
                        {
                            listsServices ?
                                <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                    {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                        return (
                                            <li key={index} className="py-1 flex items-center">
                                                <BsArrowReturnRight />
                                                <span>{item.name}</span>
                                            </li>
                                        )
                                    })}
                                    

                                </ul>
                                : null
                        }

                        <div>
                            <ButtonContent btnphone={rpdata?.dbPrincipal?.phones[0]?.phone} />
                        </div>
                    </div>
                    <div className='w-full h-auto flex flex-wrap md:w-1/2 ml-0 -mt-20 mb-0 md:ml-2 md:-my-20'>
                        {/* <img
                            src={image ? image : imgDefault}
                            alt='no found'
                            loading='lazy'
                            className='w-full h-[300px] md:h-full object-cover'
                        /> */}
                        <div className='rounded-tr-[80px] rounded-bl-[80px]  lg:mr-[5px] lg:mb-2 border-white border-[5px] shadow-sm w-[48%] h-[300px] bg-center bg-cover' style={{ backgroundImage: `url("${image1 ? image1 : imgDefault}")` }} ></div>
                        <div className='rounded-tl-[80px] rounded-br-[80px]  border-white border-[5px] shadow-sm w-[48%] h-[300px] bg-center bg-cover' style={{ backgroundImage: `url("${image2 ? image2 : imgDefault}")` }} ></div>
                        <div className='rounded-tl-[80px] rounded-br-[80px] lg:mr-[5px] lg:mt-1 border-white border-[5px] shadow-sm w-[48%] h-[300px] bg-center bg-cover' style={{ backgroundImage: `url("${image3 ? image3 : imgDefault}")` }} ></div>
                        <div className='rounded-tr-[80px] rounded-bl-[80px] lg:mt-1 border-white border-[5px] shadow-sm w-[48%] h-[300px] bg-center bg-cover' style={{ backgroundImage: `url("${image4 ? image4 : imgDefault}")` }}></div>

                    </div>
                </div>
            </div>
        </div>
    )
}


export default BlockSix;